import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Content from "../components/content/content"
import { Form, Input, FormGroup, Button, Alert } from "reactstrap"
import { fetch_post } from "../utils/fetch"
import { toast } from "react-toastify"
import { Link } from "gatsby"
import "../styles/forgot.scss"
import { Loading } from "../components/loading"
import { useAuth0 } from "@auth0/auth0-react"
import { checkPassword } from "../utils/common"
import { css } from "glamor"

export default function ResetMFA() {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [email, setEmail] = useState("")
  const [step, setStep] = useState(0)
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [inputCode, setInputCode] = useState("")
  const [markerCode, setMarkerCode] = useState("")
  const [formInvalid, setFormInvalid] = useState(true)
  const [inputInvalid, setInputInvalid] = useState("")

  //Verification Code
  const createCode = () => {
    var canvasBtn = document.getElementById("mycanvas")
    if (!canvasBtn) return
    var context = canvasBtn.getContext("2d")
    var codeNum = ""
    var codeLength = 4
    var random = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ]
    canvasBtn.width = 70
    canvasBtn.height = 36
    for (var i = 0; i < codeLength; i++) {
      var index = Math.floor(Math.random() * 36)
      codeNum += random[index]
    }
    const result = codeNum.toLowerCase()
    setMarkerCode(result)
    context.font = "20px Lato"
    context.fillStyle = "#495057"
    context.textAlign = "center"
    context.fillText(result, 35, 25)

    //Draw interference lines
    for (var l = 0; l <= 5; l++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      context.moveTo(Math.random() * 70, Math.random() * 36)
      context.lineTo(Math.random() * 70, Math.random() * 36)
      context.stroke()
    }

    //Draw interference points
    for (var p = 0; p <= 30; p++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      var x = Math.random() * 70
      var y = Math.random() * 36
      context.moveTo(x, y)
      context.lineTo(x + 1, y + 1)
      context.stroke()
    }
  }

  // Random color
  const randomColor = () => {
    var r = Math.floor(Math.random() * 256)
    var g = Math.floor(Math.random() * 256)
    var b = Math.floor(Math.random() * 256)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  useEffect(() => {
    if (!isLoading) {
      if (markerCode === "" || step === 0) {
        createCode()
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, step])

  useEffect(() => {
    setCode("")
  }, [email])

  useEffect(() => {
    // Check whether it can be committed
    if (inputCode.length <= 0) {
      setInputInvalid("success")
    } else if (String(inputCode) !== String(markerCode)) {
      setInputInvalid("error")
    } else {
      setInputInvalid("success")
    }

    if (String(inputCode) === String(markerCode) && email !== "") {
      setFormInvalid(false)
    } else {
      setFormInvalid(true)
    }
  }, [inputCode, email, markerCode])

  if (isLoading) {
    return <Loading />
  }

  const handleSubmitToGetCode = event => {
    event.preventDefault()
    if (checkPassword(isAuthenticated, user)) return
    setLoading(true)
    fetch_post(
      `submit/mfa`,
      {
        email: email,
      },
      null
    )
      .then(() => {
        setStep(1)
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (checkPassword(isAuthenticated, user)) return
    setLoading(true)
    fetch_post(
      `check/mfa`,
      {
        email: email,
        code: parseInt(code),
      },
      null
    )
      .then(() => {
        setStep(2)
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Layout pageTitle="Reset MFA">
      <Header backgroundImage="/dashborad_header.png" className="contentPage" contentPage={true}>
        <h1 className="display-4 font-weight-bold">Reset MFA</h1>
      </Header>
      <Content>
        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                {step === 0 && (
                  <>
                    <p className="card-text">Please Input your account email address</p>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Input Account Email Address"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormGroup>

                    <div className="verifyDiv">
                      <div className="markerCodeBtn">
                        <canvas id="mycanvas" onClick={createCode}></canvas>
                      </div>
                      &nbsp;&nbsp;
                      <Input
                        type="text"
                        name="inputCode"
                        id="inputCode"
                        value={inputCode}
                        placeholder="Input Captcha"
                        onChange={event => setInputCode(event.target.value)}
                      />
                    </div>
                    <FormGroup>
                      <div className="float-end title">
                        <p className="title">
                          <strong>{inputInvalid === "success" ? "" : "Wrong input"}</strong>
                        </p>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Button
                        className="btn-block"
                        color={"primary"}
                        onClick={handleSubmitToGetCode}
                        disabled={formInvalid}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          >
                            <span className="sr-only">Sending...</span>
                          </span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </FormGroup>
                  </>
                )}
                {step === 1 && (
                  <>
                    <Alert color="success">
                      If an account exists, we have sent a one-time code to your email address.
                      Please enter the code below.
                    </Alert>

                    <FormGroup>
                      <Input
                        type="code"
                        name="code"
                        id="code"
                        placeholder="Input a one-time code."
                        value={code}
                        onChange={event => setCode(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        className="btn-block"
                        color={"primary"}
                        onClick={handleSubmit}
                        disabled={formInvalid}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          >
                            <span className="sr-only">Sending...</span>
                          </span>
                        ) : (
                          "Confirm"
                        )}
                      </Button>
                      <Button
                        className="btn-block"
                        color={"primary"}
                        onClick={() => {
                          setStep(0)
                        }}
                      >
                        Back
                      </Button>
                    </FormGroup>
                  </>
                )}
                {step === 2 && (
                  <>
                    <Alert color="success">
                      The request is submitted successfully. Please check your email for next steps.
                    </Alert>
                    <Link to="/">
                      <Button className="btnBgColor">Back Home</Button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div>
              <span {...css(styles.requestContent)}>
                <img aria-hidden="true" {...css(styles.img)} src={"/support_user.svg"} />
                <a href="/faq/61440014/">
                  <strong>Request password reset</strong>
                </a>

                <span {...css(styles.description)}>
                  <p style={{ marginBottom: 0 }}>
                    Please see these instructions on how to reset your password.
                  </p>
                </span>
              </span>
            </div>
          </div>
        </div>

        <div></div>
      </Content>
    </Layout>
  )
}

const styles = {
  requestContent: {
    position: "relative",
    display: "block",
    padding: "15px 10px 15px 60px",
  },
  img: {
    position: "absolute",
    left: 0,
    width: "48px",
    height: "48px",
    padding: "5px",
  },
  supportContainer: {
    position: "relative",
    border: "0.75px solid #ebebeb",
    borderRadius: "12px",
    padding: "1rem",
  },
}
